import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";

import Layout from "../layouts";
import Seo from "../components/seo";
import GrayWoodSection from "../components/Section/GrayWoodSection";
import { DualRing, isBrowser } from "../components/Section"

import Hero from "../sections/Home/Hero";

const CategoriesSection = lazy(() => import("../sections/Home/CategoriesSection"));
const ProductSection = lazy(() => import("../sections/Home/ProductSection"));
const VideoSection = lazy(() => import("../sections/Home/VideoSection"));
const PaymentOptions = lazy(() => import("../sections/Home/PaymentOptions"));
const MapSection = lazy(() => import("../sections/Home/MapSection"));
const WhySection = lazy(() => import("../sections/Home/WhySection"));
const BlogSection = lazy(() => import("../sections/Home/BlogSection"));
const ReviewSection = lazy(() => import("../sections/Home/ReviewSection"));

const IndexPage = ({ location, data }) => {
  const categoriesData = {
    title: data.contentfulHomePage.categoriesSectionTitle,
    description: data.contentfulHomePage.categoriesSectionDescription,
    categories: data.contentfulHomePage.categoriesSections,
  };
  const paymentOptionsData = {
    title: data.contentfulHomePage.paymentOptionsTitle,
    image: data.contentfulHomePage.paymentOptionsImage,
    rto: data.contentfulHomePage.paymentRto,
    financing: data.contentfulHomePage.paymentFinancing,
  };
  const productSectionData = {
    title: data.contentfulHomePage.productsSectionTitle,
    products: data.allContentfulProduct.edges,
  };
  const reviewSectionData = {
    title: data.contentfulHomePage.reviewSectionTitle,
    reviews: data.allContentfulReview.edges
  }
  const organizationSchema = {
    "@context": "https://schema.org", 
    "@type": "Organization", 
    "name": "Buildings And More", 
    "url": "https://www.buildingsandmore.com",
    "logo": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/df682/logo.png",
    "contactPoint": { 
    "@type": "ContactPoint", 
    "telephone": "+1 (386) 755-6449", 
    "contactType": "customer service", 
    "areaServed": "US", 
    "availableLanguage": "en"
    }, 
    "sameAs": [
      "https://www.facebook.com/BuildingsAndMore/", 
      "https://www.instagram.com/buildingsandmorefl/", 
      "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
      "https://www.pinterest.com/buildingsandmore/", 
      "https://www.buildingsandmore.com/" 
    ] 
  }
  const businessSchema = { 
    "@context": "https://schema.org", 
    "@type": "LocalBusiness", 
    "name": "Buildings And More", 
    "image": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/8abb9/logo.webp", 
    "@id": "", 
    "url": "https://www.buildingsandmore.com/", 
    "telephone": "+1 (386) 755-6449", 
    "address": { 
      "@type": "PostalAddress", 
      "streetAddress": "792 SW Bascom Norris Drive", 
      "addressLocality": "Lake City", 
      "addressRegion": "FL", 
      "postalCode": "32025", 
      "addressCountry": "US" 
    }, 
    "geo": { 
      "@type": "GeoCoordinates", 
      "latitude": 30.1720092, 
      "longitude": -82.6699471 
    }, 
    "openingHoursSpecification": [{ 
      "@type": "OpeningHoursSpecification", 
      "dayOfWeek": [ 
        "Monday", 
        "Tuesday", 
        "Wednesday", 
        "Thursday", 
        "Friday" 
      ], 
      "opens": "09:00", 
      "closes": "17:00" 
    },{ 
      "@type": "OpeningHoursSpecification", 
      "dayOfWeek": "Saturday", 
      "opens": "09:00", 
      "closes": "14:00" 
    }],
    "sameAs": [
      "https://www.facebook.com/BuildingsAndMore/", 
      "https://www.instagram.com/buildingsandmorefl/", 
      "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
      "https://www.pinterest.com/buildingsandmore/", 
      "https://www.buildingsandmore.com/" 
    ] 
  }
  const schema = [organizationSchema, businessSchema]
  return (
    <Layout footerCta location={location}>
      <Seo title={data.contentfulHomePage.metaTitle} description={data.contentfulHomePage.metaDescription.metaDescription} schemaMarkup={schema} />
      <Hero data={data.contentfulHomePage.heroSection} />
      { isBrowser && (
        <>
          <Suspense fallback={<DualRing />}>
            <GrayWoodSection pt="0">
              <CategoriesSection data={categoriesData} />
              <ProductSection data={productSectionData} />
            </GrayWoodSection>
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <VideoSection data={data.contentfulHomePage.installSection} />
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <PaymentOptions data={paymentOptionsData} />
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <ReviewSection data={reviewSectionData} />
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <MapSection data={data.contentfulHomePage.mapSection} />
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <WhySection data={data.contentfulHomePage.whyBamSection} />
          </Suspense>
          <Suspense fallback={<DualRing />}>
            <BlogSection data={data.allContentfulBlog.edges} />
          </Suspense>
        </>
      )}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    contentfulHomePage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
        image {
          gatsbyImageData(quality: 50, placeholder: BLURRED, resizingBehavior: SCALE, layout: FULL_WIDTH)
        }
      }
      categoriesSectionTitle
      categoriesSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      categoriesSections {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 50, placeholder: BLURRED)
        }
        buttonText
        buttonUrl
      }
      productsSectionTitle
      installSection {
        videoUrl
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      paymentOptionsTitle
      paymentOptionsImage {
        gatsbyImageData(placeholder: BLURRED, quality: 50)
      }
      paymentRto {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          icon {
            file {
              url
            }
          }
        }
        buttonUrl
        buttonText
      }
      paymentFinancing {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          icon {
            file {
              url
            }
          }
        }
        buttonUrl
        buttonText
      }
      mapSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 50, width: 1200, layout: CONSTRAINED)
        }
      }
      reviewSectionTitle
      whyBamSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        videoUrl
        image {
          file {
            url
          }
        }
        buttonUrl
        buttonText
        features {
          icon {
            file {
              url
            }
          }
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulReview {
      edges {
        node {
          author
          role
          reviewText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
        }
      }
    }
    allContentfulBlog {
      edges {
        node {
          title
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          author {
            name
          }
          createdAt(formatString: "MMM DD, yy")
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
  }
`;
